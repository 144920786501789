import React from 'react';
import {Row, Col, Container} from 'react-bootstrap';
import styled from 'styled-components';

export const StyledFooter = styled.footer`
    background-color: #79A8A9;
    display: flex;
    width: 100%;
    height: 300px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    font-size: 18px;
    margin-top: 20px;
`;
