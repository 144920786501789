import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import styled from "styled-components";
import {Row, Col, Container, Button} from 'react-bootstrap';


export const StyledNavbar = styled(Navbar)`
    background-color: #1F4E5F;
    display: flex;
    width: 100%;
`;

export const StyledCol = styled(Col)`
    display: flex;
    width: 100%;
    justify-content: end;
    margin-left: 20px;
`;

export const StyledColBanner = styled(Col)`
    display: flex;
    width: 100%;
    margin-top: 10px;
    ,margin-left: 20px;
`;


export const StyledLogo = styled.img`
    width: 160px;
    margin-top: 10px;
    @media (max-width: 768px) {
        width: 100px;
    }
`;

export const StyledBanner = styled.img`
    width: 100%;
    height: 100px;
    margin-top: 20px;
    @media (max-width: 768px) {
        width: 80%;
    }
`;

export const StyledNavLink = styled(Nav.Link)`
    margin-left: 20px;
    margin-top: 10px;
    padding: 10px;
    border : 1px solid white;
    color: white;
    &:hover {
        color: #1F4E5F;
        background-color: white;
    }
    border-radius: 5px;
    font-size: 14px;
    text-decoration: none;
    @media (max-width: 768px) {
        font-size: 12px;
    }
    @media (max-width: 468px) {
        font-size: 10px;
    }
`;

export const StyledNav = styled(Nav)`
    margin-top: 10px;
    margin-left: 20px;
    margin-bottom: 10px;
    padding: 10px;
    display: flex;
    width: 100%;
    @media (max-width: 768px) {
        justify-content: left;
        margin-left: 0px;
    }
    @media (max-width: 468px) {
        justify-content: left;
        margin-left: 0px;
        padding: 0px;
    }
`;

