import React from 'react';
import { Col, Row, Container} from 'react-bootstrap';
import styled from 'styled-components';

export const StyledRowTittle = styled(Row)`
    margin-top: 20px;
`;

export const StyledColTittle = styled(Col)`
    text-align: center;
    margin-top: 20px;
`;

export const StyledRow = styled(Row)`
    margin-left: 30px;
    margin-right: 30px;
`;

export const StyledCol = styled(Col)`
    margin-top: 10px;
    margin-bottom: 20px;
`;