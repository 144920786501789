import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import {StyledNav, StyledCol, StyledNavbar, StyledLogo, StyledBanner, StyledNavLink, StyledColBanner} from '../components/styles/NavBar.styled';

function AoNav() {
    return (
        <>
            <StyledNavbar>
                    <Container style={{justifyContent:'-moz-initial'}}>
                        <Col>
                            <Row>
                                <StyledColBanner>
                                    <Navbar.Brand as={Link} to="/" ><StyledLogo src={require('../images/index/consulting/logo.png')} alt="Logo-Anguita-Osorio" /></Navbar.Brand>
                                    <StyledBanner src={require('../images/index/consulting/banner.png')} alt="Banner-Anguita-Osorio" />
                                </StyledColBanner>
                            </Row>
                            <Row>
                                <StyledCol>
                                    <Navbar className="navbar-expand-lg" collapseOnSelect>
                                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                        <Navbar.Collapse id="basic-navbar-nav">
                                            <StyledNav className="mr-auto">
                                                <Nav.Item>
                                                    <StyledNavLink as={Link} to="/">Home</StyledNavLink>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <StyledNavLink as={Link} to="/agregarQuerellas">Nueva Querella</StyledNavLink>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <StyledNavLink as={Link} to="/centroComando">Centro de Comando</StyledNavLink>
                                                </Nav.Item>
                                            </StyledNav>
                                        </Navbar.Collapse>
                                    </Navbar>
                                </StyledCol>
                            </Row>
                        </Col>
                    </Container>
            </StyledNavbar>
        </>
    );  
}

export default AoNav;