import axios from "axios";
import {Model} from "../Model/Model";

/**
 * Typed axios get call
 * TODO: expand to accept config for better axios functionality.
 * @param url
 */
export async function axiosJsonData<T extends Model>(url: string): Promise<T[]> {
    try {
        const response = await axios.get(url);
        return response.data as Promise<T[]>;
    } catch (error: any) {
        throw new Error(`Error in 'axiosGetJsonData(${url})': ${error.message}`);
    }
}

export async function axiosPostJsonData(url: string, data: any){
    try {
        console.log(data);
        const response = await axios.post(url, data,
            {
                headers: {'Access-Control-Allow-Origin':"*",
                    'Access-Control-Allow-Credentials':"true"}
            });
        return response;
    } catch (error: any) {
        throw new Error(`Error in 'axiosGetJsonData(${url})': ${error.message}`);
    }
}

export async function axiosDeleteJsonData(url: string, id: String){
    try {
        const deleteUrl = `${url}/${id}`
        console.log(deleteUrl)
        const response = await axios.delete(deleteUrl,
            {
                headers: {'Access-Control-Allow-Origin':"*",
                    'Access-Control-Allow-Credentials':"true"}
            });
        return response;
    } catch (error: any) {
        throw new Error(`Error in 'axiosGetJsonData(${url})': ${error.message}`);
    }
}