import React, { useState, ChangeEvent } from 'react';
import { signIn } from './AuthService';
import { callApi } from './ApiService';
import {Form, Button, Container, Row, Col, InputGroup} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-router-dom';
import {
  StyledCol,
  StyledButton
} from '../components/styles/SigLogin.styled';
import backgroundImage from '../images/anguitaosorio.png';

function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSignIn = async () => {
    try {
      const token = await signIn(email, password);
      const data = await callApi('login');
      console.log(data);
    } catch (error) {
      console.error('An error occurred', error);
    }
  };

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  return (
    <Col md={12} style={{
        padding:"30px",
        display:"flex",
        justifyContent:"flex-end",
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight:"100vh"
    }} >
      <StyledCol xs={12} sm={10} md={8} lg={4} className="ml-auto">
        <Container className="my-5">
          <h2 className="text-center mb-4" style={{color:"white"}}>USER LOGIN</h2>
          <Form>
    <Form.Group controlId="formBasicEmail">
      <Form.Label>User</Form.Label>
      <InputGroup>
          <FontAwesomeIcon style={{color:"white", fontSize:"30px", margin:"10px"}} icon={faUser} />
        <Form.Control type="text" placeholder="User" name="name"  onChange={handleEmailChange} />
      </InputGroup>
    </Form.Group>
    <Form.Group controlId="formBasicPassword">
      <Form.Label>Password</Form.Label>
      <InputGroup>
            <FontAwesomeIcon style={{color:"white", fontSize:"30px", margin:"10px"}} icon={faLock} />
        <Form.Control type="password" placeholder="Password" name="password" onChange={handlePasswordChange} />
      </InputGroup>
    </Form.Group>
            <Row>
              <Col style={{marginTop:"10px"}}>
                <Link style={{textDecoration:"none", color:"white"}} to="/ForgotPassword">Forgot Password?</Link>
              </Col>
            </Row>
            <Row style={{justifyContent:"center"}}>
              <StyledButton type="button" onClick={handleSignIn}>
                Sign In
              </StyledButton>
            </Row>
            <Row>
              <Col>
                <Link style={{textDecoration:"none", color:"white"}} to="/SignUp">Create Account</Link>
              </Col>
            </Row>
          </Form>
        </Container>
      </StyledCol>
    </Col>
  );
}

export default SignIn;
