import { CognitoUser, CognitoUserPool, AuthenticationDetails } from 'amazon-cognito-identity-js';

const userPool = new CognitoUserPool({
  UserPoolId: 'us-east-1_2SxTQqh8F',
  ClientId: '5cm8001j1fii27uq4rvpb0m1rb',
});

export const signIn = (email: string, password: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    cognitoUser.authenticateUser(authDetails, {
      onSuccess: (result) => {
        const token = result.getAccessToken().getJwtToken();
        console.log("Token received:", token); 
        localStorage.setItem('accessToken', token);
        resolve(token);
      },
      onFailure: (err) => {
        console.error("Authentication failed:", err);
        reject(err);
      },
    });
  });
};

export const signOut = () => {
  localStorage.removeItem('accessToken');
};
