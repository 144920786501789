import axios, { AxiosError } from 'axios';

const API_URL = 'https://login.anguitaosorio.com/';

interface Callbacks {
  onUnauthorized?: () => void;
  onNetworkError?: () => void;
  
  // Agrega aquí otros callbacks según lo necesites
}

export const callApi = async (
  endpoint: string,
  method: string = 'POST',
  body?: any,
  callbacks?: Callbacks
): Promise<any> => {
  const token = localStorage.getItem('accessToken');
  if (!token) {
    throw new Error('No token present');
  }

  const headers = {
    Authorization: token,
    'Access-Control-Allow-Origin':"*",
    'Access-Control-Allow-Credentials':"true",
    'Content-Type': 'application/json',
  };

  try {
    const response = await axios({
      url: API_URL + endpoint,
      method,
      headers,
      data: body,
    });
    
    return response.data;

  } catch (error) {
    const axiosError = error as AxiosError;  // <-- Type assertion
    console.error('Error making API call', axiosError);
  
    if (axiosError.response) {
      switch (axiosError.response.status) {
        case 401:
          callbacks?.onUnauthorized && callbacks.onUnauthorized();
          throw new Error('Unauthorized. Please log in again.');
        case 403:
          throw new Error('Forbidden. You do not have permission.');
        // Puedes agregar más códigos de estado aquí
        default:
          throw new Error('HTTP status ' + axiosError.response.status);
      }
    } else if (axiosError.request) {
      callbacks?.onNetworkError && callbacks.onNetworkError();
      throw new Error('Network error. Please try again later.');
    } else {
      throw new Error('An error occurred. Please try again.');
    }
  }
};