import React from 'react';
import { Container} from 'react-bootstrap';
import { FormRenderer} from '@data-driven-forms/react-form-renderer';
import componentMapper from '@data-driven-forms/mui-component-mapper/component-mapper';
import FormTemplate from '@data-driven-forms/mui-component-mapper/form-template/form-template';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import { StyledRowTittle, StyledRow, StyledColTittle, StyledCol } from './styles/NuevaQuerella.styled';
import {axiosPostJsonData} from "../lib/axiosJsonData";

const schema = {
    fields: [
        {
            component: 'text-field',
            name: 'lugarHecho',
            label: 'Lugar de los hechos',
            isRequired: true,
            validate: [
                {type: 'required',message: 'Este campo es requerido'},
                {type: 'min-length', message: 'Debe tener al menos 3 caracteres', threshold: 3},
                {type: 'max-length', message: 'Debe tener menos de 50 caracteres', threshold: 50},],
        },
        {
            component: 'date-picker',
            name: 'fechaHechos',
            label: 'Fecha de los hechos',
            isRequired: true,
            validate: [
                {type: 'required',message: 'Este campo es requerido'}
                ],

        },
        {
            component: 'select',
            name: 'tipo de hecho',
            label: 'Tipo de Hecho',
            isRequired: true,
            validate: [{type: 'required',message: 'Este campo es requerido'}],
            options: [
                {
                    label: 'Accidente Automovilistico',
                    value: 'AccidenteVehiculoCheck',
                },
                {
                    label: 'Robo de cable',
                    value: 'RoboCableCheck',
                },
                {
                    label: 'No entrega informacion',
                    value: 'NoEntregaInformacionCheck',
                },
                {
                    label: 'Robo en tienda',
                    value: 'RoboTiendaCheck',
                },
            ],

        },
        {
            component: "field-array",
            name: 'hechos',
            fieldKey: 'nombre',
            label: 'Datos del Accidente Automovilistico',
            description: 'Por favor agregar los datos solicitados',
            minItems: 1,
            maxItems: 1,
            initialValue: [null],
            fields: [
                {
                    component: 'text-field',
                    name: 'nombreConductor',
                    label: 'Nombre del conductor',
                    validate: [
                        {type: 'min-length', message: 'Debe tener al menos 3 caracteres', threshold: 3},
                        {type: 'max-length', message: 'Debe tener menos de 50 caracteres', threshold: 50}
                    ],
                },
                {
                    component: 'text-field',
                    name: 'patente',
                    label: 'Patente del vehiculo',
                    validate : [
                        {
                            type: 'pattern',
                            pattern: '^[A-Z]{4}[0-9]{2}',
                            message: 'Patente invalida',
                        },
                    ],
                },
                {
                    component: 'text-field',
                    name: 'calle',
                    label: 'Calle por donde circulaba',
                    validate: [
                        {type: 'min-length', message: 'Debe tener al menos 3 caracteres', threshold: 3},
                        {type: 'max-length', message: 'Debe tener menos de 50 caracteres', threshold: 50}
                    ],
                },
                {
                    component: 'text-field',
                    name: 'direccion',
                    label: 'Direccion',
                    validate: [
                        {type: 'min-length', message: 'Debe tener al menos 3 caracteres', threshold: 3},
                        {type: 'max-length', message: 'Debe tener menos de 50 caracteres', threshold: 50}
                    ],
                },
                {
                    component: 'text-field',
                    name: 'velocidadVehiculo',
                    label: 'Velocidad del vehiculo',
                    type : 'number',
                },
                {
                    component: 'radio',
                    name: 'respetoNormas',
                    label: 'Respeto de las normas de transito',
                    options: [
                        {
                            label: 'Si',
                            value: 'true',
                        },
                        {
                            label: 'No',
                            value: 'false',
                        },
                    ],
                    initialValue: '1',
                    initializeOnMount: true,
                    clearOnUnmount: true,
                },
                {
                    component: 'checkbox',
                    name: 'respetoDerechoPaso',
                    label: 'No respeto el derecho de paso',
                    condition : {
                        when: ((field:any) => field.name.replace(/\..*$/, 'respetoNormas')),
                        is: 'false',
                    },
                },
                {
                    component: 'checkbox',
                    name: 'respetoLuzRoja',
                    label: 'No respeto la luz roja de un semaforo',
                    condition : {
                        when: ((field:any) => field.name.replace(/\..*$/, 'respetoNormas')),
                        is: 'false',
                    },
                },
                {
                    component: 'checkbox',
                    name: 'respetoDiscoPare',
                    label: 'Paso un disco pare',
                    condition : {
                        when: ((field:any) => field.name.replace(/\..*$/, 'respetoNormas')),
                        is: 'false',
                    },
                },
                {
                    component: 'checkbox',
                    name: 'respetoCrucePeatonal',
                    label: 'No respeto cruce de peatones',
                    condition : {
                        when: ((field:any) => field.name.replace(/\..*$/, 'respetoNormas')),
                        is: 'false',
                    },
                },
                {
                    component: 'checkbox',
                    name: 'respetoCedaElPaso',
                    label: 'No respeto ceda el paso',
                    condition : {
                        when: ((field:any) => field.name.replace(/\..*$/, 'respetoNormas')),
                        is: 'false',
                    },
                },
                {
                    component: 'text-field',
                    name: '_type',
                    initialValue:"AccidenteVehiculo",
                    hideField: true
                },
            ],condition : {
                when: 'tipo de hecho',
                is: 'AccidenteVehiculoCheck',
            },
        },
        {
            component: "field-array",
            name: 'hechos',
            fieldKey: 'nombre',
            label: 'Datos del Relacionados a la no entrega de informacion',
            description: 'Por favor agregar los datos solicitados',
            minItems: 1,
            maxItems: 1,
            initialValue: [null],
            fields: [{
                component: 'text-field',
                name: 'nombreCliente',
                label: 'Nombre del cliente',
                isRequired: true,
                validate: [
                    {type: 'required',message: 'Este campo es requerido'},
                    {type: 'min-length', message: 'Debe tener al menos 3 caracteres', threshold: 3},
                    {type: 'max-length', message: 'Debe tener menos de 50 caracteres', threshold: 50}
                ],
            },
                {
                    component: 'text-field',
                    name: 'informacionFalsa',
                    label: 'Informacion falsa entregada',
                    isRequired: true,
                    validate: [
                        {type: 'required',message: 'Este campo es requerido'},
                        {type: 'min-length', message: 'Debe tener al menos 3 caracteres', threshold: 3},
                        {type: 'max-length', message: 'Debe tener menos de 50 caracteres', threshold: 50}
                    ],
                },
                {
                    component: 'text-field',
                    name: 'montoSolicitado',
                    label: 'Monto solicitado',
                    isRequired: true,
                    validate: [
                        {type: 'required',message: 'Este campo es requerido'},
                    ],
                    type : 'number',
                },
                {
                    component: 'date-picker',
                    name: 'fechaSolicitud',
                    label: 'Fecha en que solicito el prestamo',
                    isRequired: true,
                    validate: [
                        {type: 'required',message: 'Este campo es requerido'},
                    ],
                },
                {
                    component: 'date-picker',
                    name: 'fechaDejaPagar',
                    label: 'Fecha en que dejo de pagar',
                    isRequired: true,
                    validate: [{type: 'required',message: 'Este campo es requerido'}],
                },
                {
                    component: 'text-field',
                    name: 'montoAdeudado',
                    label: 'Monto adeudado',
                    isRequired: true,
                    validate: [{type: 'required',message: 'Este campo es requerido'}],
                    type : 'number',
                },
                {
                    component: 'text-field',
                    name: '_type',
                    initialValue:"NoEntregaInformacion",
                    hideField: true
                },
                ],
                condition : {
                    when: 'tipo de hecho',
                    is: 'NoEntregaInformacionCheck',
                },
        },
        {
            component: "field-array",
            name: 'hechos',
            fieldKey: 'nombre',
            label: 'Datos del Robo de Cables',
            description: 'Por favor agregar los datos solicitados',
            minItems: 1,
            maxItems: 1,
            initialValue: [null],
            fields: [
                {
                    component: 'text-field',
                    name: 'nombre',
                    label: 'Nombre del Imputado',
                    validate: [
                        {type: 'required',message: 'Este campo es requerido'},
                        {type: 'min-length', message: 'Debe tener al menos 3 caracteres', threshold: 3},
                        {type: 'max-length', message: 'Debe tener menos de 50 caracteres', threshold: 50}
                    ],
                },
                {
                    component: 'text-field',
                    name: 'telefono',
                    label: 'Telefono del Imputado',
                    validate: [
                        {
                            type: 'pattern',
                            pattern: /^\+569[0-9]{8}$/,
                            message: 'El teléfono debe tener el formato +569XXXXXXXX',
                        },
                    ],
                },
                {
                    component: 'text-field',
                    name: 'ubicacion',
                    label: 'Ubicacion donde ocurrieron los hechos',
                    validate: [
                        {type: 'required',message: 'Este campo es requerido'},
                        {type: 'min-length', message: 'Debe tener al menos 3 caracteres', threshold: 3},
                        {type: 'max-length', message: 'Debe tener menos de 50 caracteres', threshold: 255}
                    ],
                },
                {
                    component: 'text-field',
                    name: '_type',
                    initialValue:"RoboCable",
                    hideField: true
                },
            ],
            condition : {
                when: 'tipo de hecho',
                is: 'RoboCableCheck',
            },
        },
        {
            component: "field-array",
            name: 'testigos',
            fieldKey: 'testigos',
            label: 'Datos de los testigos',
            description: 'Por favor agregar los nombres y datos de contacto de los testigos',
            minItems: 1,
            initialValue: [null],
            fields: [
                {
                    component: 'text-field',
                    name: 'nombre',
                    label: 'Nombre del testigo',
                    validate: [
                        {type: 'required',message: 'Este campo es requerido'},
                        {type: 'min-length', message: 'Debe tener al menos 3 caracteres', threshold: 3},
                        {type: 'max-length', message: 'Debe tener menos de 50 caracteres', threshold: 50}
                    ],
                },
                {
                    component: 'text-field',
                    name: 'telefono',
                    label: 'Telefono del testigo',
                    validate: [
                        {
                            type: 'pattern',
                            pattern: /^\+569[0-9]{8}$/,
                            message: 'El teléfono debe tener el formato +569XXXXXXXX',
                        },
                    ],
                },
            ],
        },
        {
            component: "field-array",
            name: 'descubridores',
            fieldKey: 'descubridores',
            label: 'Datos de Personas que Descubren el Hecho',
            description: 'Por favor agregar los nombres y datos de contacto',
            minItems: 1,
            initialValue: [null],
            fields: [
                {
                    component: 'text-field',
                    name: 'nombre',
                    label: 'Nombre de la persona que descubrio el hecho',
                    validate: [
                        {type: 'required',message: 'Este campo es requerido'},
                        {type: 'min-length', message: 'Debe tener al menos 3 caracteres', threshold: 3},
                        {type: 'max-length', message: 'Debe tener menos de 50 caracteres', threshold: 50}
                    ],
                },
                {
                    component: 'text-field',
                    name: 'telefono',
                    label: 'Telefono de la persona que descubrio el hecho',
                    validate: [
                        {
                            type: 'pattern',
                            pattern: /^\+569[0-9]{8}$/,
                            message: 'El teléfono debe tener el formato +569XXXXXXXX',
                        },
                    ],
                },
            ],
        },
        {
            component: "field-array",
            name: 'policias',
            fieldKey: 'policias',
            label: 'Datos de los oficiales de policia',
            description: 'Por favor agregar los datos oficiales de policia',
            minItems: 1,
            initialValue: [null],
            fields: [
                {
                    component: 'text-field',
                    name: 'nombre',
                    label: 'Nombre del policia',
                    validate: [
                        {type: 'required',message: 'Este campo es requerido'},
                        {type: 'min-length', message: 'Debe tener al menos 3 caracteres', threshold: 3},
                        {type: 'max-length', message: 'Debe tener menos de 50 caracteres', threshold: 50}
                    ],
                },
                {
                    component: 'text-field',
                    name: 'telefono',
                    label: 'Telefono de la persona que descubrio el hecho',
                    validate: [
                        {
                            type: 'pattern',
                            pattern: /^\+569[0-9]{8}$/,
                            message: 'El teléfono debe tener el formato +569XXXXXXXX',
                        },
                    ]
                },
            ],
        },
        {
            component: 'checkbox',
            name: 'DatosPartePolicial',
            label: 'Datos del parte policial si lo hubiera',
        },
        {
            component: 'textarea',
            name: 'Partepolicial',
            label: 'Parte policial',
            condition : {
                when: 'DatosPartePolicial',
                is: true,
            },
        },
        {
            component: 'checkbox',
            name: 'fotoLugar',
            label: 'Tiene Fotos del lugar',
        },
        {
            component: 'textarea',
            name: 'foto',
            label: 'Fotos del lugar',
            condition : {
                when: 'fotoLugar',
                is: true,
            },
        },
        {
            component: 'checkbox',
            name: 'videoLugar',
            label: 'Tiene Videos del lugar',
        },
        {
            component: 'textarea',
            name: 'video',
            label: 'Videos del lugar',
            condition : {
                when: 'videoLugar',
                is: true,
            },
        },
        {
            component: 'checkbox',
            name: 'documentoConsidereNecesario',
            label: 'Documentos que considere necesarios',
        },
        {
            component: 'textarea',
            name: 'documentos',
            label: 'Documentos',
            condition : {
                when: 'documentoConsidereNecesario',
                is: true,
            },
        },
        {
            component: 'checkbox',
            name: 'otrasPurebas',
            label: 'Otros medios de prueba importantes',
        },
        {
            component: 'textarea',
            name: 'pruebas',
            label: 'Otros medios de prueba',
            condition : {
                when: 'otrasPurebas',
                is: true,
            },
        },
    ],
};


const NuevaQuerella = ({apiUrl}:{apiUrl: string}) => (
    <>
        <Container >
            <StyledRowTittle >
                <StyledColTittle >
                    <h2>Nueva Querella</h2>
                </StyledColTittle>
            </StyledRowTittle>
            <StyledRow>
                <StyledCol>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <FormRenderer
                            schema={schema}
                            componentMapper={componentMapper}
                            FormTemplate={FormTemplate}
                            onSubmit={(values) => axiosPostJsonData(apiUrl, values)}
                        />
                    </LocalizationProvider>
                </StyledCol>
            </StyledRow>
        </Container>
    </>
)


export default NuevaQuerella;

