import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Route, Routes, Navigate } from 'react-router-dom';  // Importa Navigate
import Home from "./pages/Home";
import CentroComando from "./pages/CentroComando";
import AgregarQuerellas from "./pages/agregarQuerellas";
import GlobalStyle from './components/styles/Global.styled';
import {querellaApiUrl} from "./const/querellaUrl";
import { CognitoIdentityServiceProvider, CognitoIdentityCredentials, config as AWSConfig } from 'aws-sdk';
import SignIn from './services/SigIn';

AWSConfig.region = 'your-region';             //copiar region
AWSConfig.credentials = new CognitoIdentityCredentials({
  IdentityPoolId: 'your-identity-pool-id'    //copiar pool id
});


function App() {
  let current_language = "ES";
  return <>
    <GlobalStyle />
    <Routes>
      <Route path="/signIn" element={<SignIn />} />
      <Route path="/" element={<Navigate to="/signIn" />} />  
      <Route path="/centroComando" element={<CentroComando />} />
      <Route path="/agregarQuerellas" element={<AgregarQuerellas apiUrl={querellaApiUrl}/>} />
    </Routes>
  </>
}

export default App;
