import React from 'react';
import {Col, Row} from 'react-bootstrap';
import AoNav from '../components/AoNav';
import Footer from '../components/Footer';
import NuevaQuerella from '../components/NuevaQuerella';

const AgregarQuerellas = ({apiUrl}:{apiUrl: string}) => {
    return (
        <>
            <AoNav />
            <NuevaQuerella apiUrl={apiUrl}/>
            <Footer/>
        </>
    );
}

export default AgregarQuerellas;



