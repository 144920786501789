import { createGlobalStyle } from 'styled-components';

//import MetaSerifProBookWoof from '../../fonts/MetaSerifPro-Book.woff'
//import MetaSerifProBookWoof2 from '../../fonts/MetaSerifPro-Book.woff2'
//import MetaSerifProMediWoof from '../../fonts/MetaSerifPro-Medi.woff'
//import MetaSerifProMediWoof2 from '../../fonts/MetaSerifPro-Medi.woff2'
//import NexaBoldWoff from '../../fonts/nexa_bold-webfont.woff'
//import NexaBoldWoff2 from '../../fonts/nexa_bold-webfont.woff2'

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'MetaSerifPro-Book';
        src: local('MetaSerifPro-Book'), local('MetaSerifPro-Book'),
    }
    body{
        font-family: 'nexa-bolduploaded_file', sans-serif;
        color: #AACFD0;
    }
    h1, h2, h3, h4, h5, h6 {
        color: black;
    }
    
`;

export default GlobalStyle;