import React from 'react';
import MaterialReactTable from 'material-react-table';
import type { MRT_ColumnDef } from 'material-react-table';
import {Col, Row, Container} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import {StyledCol} from './styles/CentroComandoTable.styled';

interface YourDataType {
    descripcion: string;
    date : string;
    estado: string;
    actions: JSX.Element;
}

const data: YourDataType[] = [
    {
    descripcion: `Pepito vs Quien Resulte responsable, 
        Victima(s): Pepito Los Pelotes
        Hechos: 
            -Accidente automovilistico: Avenida Matta 123 
    `,
    date: '2021-10-12',
    estado: 'En proceso',
    actions: <Button variant="light" href="">Ver mas</Button>,
    },
    {
        
        descripcion: `Santander vs Juanito,
        Victima(s): Santander
        Hechos:
            -Entreog informacion falsa: Avenida Matta 123
    `,
        date: '2021-10-12',
        estado: 'En proceso',
        actions: <Button variant="light" href="">Ver mas</Button>,
    },
]

const columns: MRT_ColumnDef<YourDataType>[] = [
    {
        accessorKey: 'descripcion',
        header: 'Descripcion',
    },
    {
        accessorKey: 'date',
        header: 'Fecha',
    },
    {
        accessorKey: 'estado',
        header: 'estado',
    },
    {
        accessorKey: 'actions',
        header: 'Acciones',
    }
];

const CentroComandoTable = () => {
    return (
        
        <>
            <Container>
                <Row>
                    <StyledCol>
                        <h2>Centro de Comando</h2>
                    </StyledCol>
                </Row>
                <Row>
                    <StyledCol>
                        <MaterialReactTable
                            columns={columns}
                            data={data}
                        />
                    </StyledCol>
                </Row>
            </Container>
        </>
    );
}

export default CentroComandoTable;



