import react from 'react';
import {Col, Container, Row, } from 'react-bootstrap';
import {StyledFooter} from './styles/Footer.styled';

const Footer = () => (
    <StyledFooter>
        <Container>
            <Row >
                <Col>© 2022 AnguitaOsorio, todos los derechos reservados.</Col>
            </Row>
        </Container>
    </StyledFooter>
)

export default Footer;