import React from 'react';
import {Row, Col, Container} from 'react-bootstrap';
import styled from 'styled-components';

export const StyledCol = styled(Col)`
    background-color: #71D8DB;
    display: flex;
    border-radius: 20px;
    padding: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

export const StyledButton = styled.button`
    margin-top: 20px;
    margin-bottom: 10px;
    max-width: 30%;
    border-radius: 20px;
    text-align: center;
    border: none;
    color: #71D8DB;
    background-color: white;
`;
