import React from 'react';
import AoNav from '../components/AoNav';
import Footer from '../components/Footer';
import CentroComandoTable from '../components/CentroComandoTable';

const CentroComando = () => {
    return (
        <>
            <AoNav />
            <CentroComandoTable />
            <Footer/>
        </>
    );
}

export default CentroComando;